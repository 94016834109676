import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { removeSpaces } from '../../../../shared/lib/utils';
import api from '../../shared/lib/api';
import { IKontaktperson, IRadgiver } from '../../shared/lib/types';
import Radgivere from './Radgivere';
import KontaktPerson from './KontaktPerson';
import { createKontaktperson, mapKontaktpersonToKontaktpersonForm, mapRadgiverToKontaktperson, updateInput } from '../kundedetaljer/kundeFormHelper';
import TilbakeTil from '../Tilbake';
import Spinner from '../../spinner/Spinner';
import FeilmeldingSide from '../../feilside/FeilmeldingSide';
import toast from 'react-hot-toast';
import { IEpostForm, createForm, mapEpostFormToEpost, mapEpostToEpostForm } from './EpostFormHelper';
import Kontaktperson from '../../bedrift/Kontaktperson';
import { getValidationResult, validateInput } from '../../../../shared/lib/validators';
import TextInput from '../../../form/TextInput';
import CheckboxInput from '../../../form/CheckboxInput';
import TextareaInput from '../../../form/TextareaInput';
import Modal from '../../../modal/Modal';

type TParams = {
    dokumentId?: string;
    kundeId?: string;
    tittel?: string;
};

export default function SendEpostDigitalSignering() {
    const { dokumentId, kundeId, tittel } = useParams<TParams>();
    const [kundeNavn, setKundeNavn] = useState<string>();
    const [egetforetak, setEgetForetak] = useState<boolean>(false);
    const [radgivere, setRadgivere] = useState<Array<IRadgiver>>([]);
    const [kontaktPersoner, setKontaktPersoner] = useState<Array<IKontaktperson>>([]);
    const [valgteRadgivere, setValgteRadgivere] = useState<Array<string>>([]);
    const [valgtePersoner, setValgtePersoner] = useState<Array<string>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const [epostForm, setEpostForm] = useState<IEpostForm>(mapEpostToEpostForm(createForm(tittel)));
    const isExternalApp = window.isExternalApp;

    let navigate = useNavigate();

    const onInputChange = (value: any) => {
        setValgteRadgivere(value);
    };
    const onPersonInputChange = (value: any) => {
        setValgtePersoner(value);
    };

    const onSendEpost = () => {
        const result = getValidationResult(epostForm);
        setEpostForm(result.form as IEpostForm);

        if (result.errors.length > 0) {
            return;
        }

        let epostSignering = mapEpostFormToEpost(epostForm);
        const radgiverListe = radgivere.filter((radgiver) => valgteRadgivere.includes(radgiver.brukerId)) as Array<IRadgiver>;
        const kontaktpersonListe = kontaktPersoner.filter((person) => valgtePersoner.includes(person.id)) as Array<IKontaktperson>;
        const mappedRadgivere = radgiverListe.map((r) => mapRadgiverToKontaktperson(r));

        epostSignering.kontaktpersoner.push(...kontaktpersonListe);
        epostSignering.kontaktpersoner.push(...mappedRadgivere);

        if (epostSignering.kontaktpersoner.length > 0 && !isExternalApp) {
            if (egetforetak) {
                const result = toast.promise(api.sendSigneringEpostEgetForetak(dokumentId, epostSignering), {
                    loading: 'Sender dokumentet til signering...',
                    success: <b>Veilederen er sendt over til signering.</b>,
                    error: <b>Kunne dokumentet til signering.</b>,
                });
                result
                    .then(() => {
                        if (isExternalApp) return window.close();
                        else return navigate(`/kunder/${removeSpaces(kundeId)}/genererte-dokumenter`);
                    })
                    .catch((error) => console.log('error', error));
            } else {
                const result = toast.promise(api.sendSigneringEpostKunde(kundeId, dokumentId, epostSignering), {
                    loading: 'Sender dokumentet til signering...',
                    success: <b>Veilederen er sendt over til signering.</b>,
                    error: <b>Kunne dokumentet til signering.</b>,
                });

                result
                    .then((r) => {
                        if (isExternalApp) return window.close();

                        return navigate(`/kunder/${removeSpaces(kundeId)}/genererte-dokumenter`);
                    })
                    .catch((error) => console.log('error', error));
            }
        } else {
            toast.error('Vennligst velg en mottaker.');
        }
    };

    useEffect(() => {
        (() => {
            Promise.all([
                api.getInnloggetBruker().then(async (res) => {
                    if (res.regnskapsforetakId === kundeId) {
                        setKundeNavn(res.regnskapforetakNavn);
                        setEgetForetak(true);
                        const radgivere = await api.getRadgivere();
                        return setRadgivere(radgivere);
                    } else {
                        const kunde = await api.getKunde(kundeId);
                        const kontaktpersoner = kunde.kontaktpersoner;
                        kontaktpersoner.push(kunde.dagligLeder);
                        setKundeNavn(kunde.navn);
                        setKontaktPersoner(kontaktpersoner);
                        if (kunde.harBegrensetKundesynlighet) {
                            const radgivere = kunde.rettigheter;
                            setRadgivere(radgivere);
                        } else {
                            return api.getRadgivere().then((radgivere) => setRadgivere(radgivere));
                        }
                    }
                }),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Send epost';
        })();
    }, [dokumentId, kundeId, tittel]);

    function onDeleteKontaktpersonClick(index: number) {
        let kontaktpersoner = [...(epostForm.andreMottakere ?? [])];
        kontaktpersoner.splice(index, 1);
        setEpostForm({ ...epostForm, andreMottakere: kontaktpersoner });
    }

    function onAddKontaktperson() {
        let kontaktpersoner = [...(epostForm.andreMottakere ?? [])];
        kontaktpersoner.push(mapKontaktpersonToKontaktpersonForm(createKontaktperson(), `andremottakere[${epostForm.andreMottakere.length}]`));
        setEpostForm({ ...epostForm, andreMottakere: kontaktpersoner });
    }

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <div className="sende-post-digital-signering">
            <div className="page-header">
                <div className="container">
                    <span className={`icon ${egetforetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                    <h2>{kundeNavn && kundeNavn}</h2>
                </div>
            </div>
            {!isExternalApp && <TilbakeTil url={`/kunder/${removeSpaces(kundeId)}/genererte-dokumenter`} tekst="Tilbake til dokumentarkiv" />}
            <div className="page">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-7">
                            <div className="section section--white">
                                <h2>Send dokument til signering</h2>
                                {!loading && epostForm && (
                                    <div>
                                        <TextInput {...epostForm.tittel} readonly={true} />
                                        <TextInput
                                            {...epostForm.emne}
                                            onInputChange={(name, value) => {
                                                setEpostForm({
                                                    ...epostForm,
                                                    emne: updateInput(epostForm.emne, value),
                                                });
                                            }}
                                            onInputBlur={(name) => {
                                                setEpostForm({
                                                    ...epostForm,
                                                    emne: validateInput(epostForm.emne),
                                                });
                                            }}
                                        />
                                        <TextareaInput
                                            {...epostForm.melding}
                                            onInputChange={(name, value) => {
                                                setEpostForm({
                                                    ...epostForm,
                                                    melding: updateInput(epostForm.melding, value),
                                                });
                                            }}
                                            onInputBlur={(name) => {
                                                setEpostForm({
                                                    ...epostForm,
                                                    emne: validateInput(epostForm.emne),
                                                });
                                            }}
                                        />
                                        <div className="form-group--checkbox__content">
                                            <CheckboxInput
                                                {...epostForm.tillatAnalogSignatur}
                                                checked={epostForm.tillatAnalogSignatur.value}
                                                index={0}
                                                onInputChange={(name, value) => {
                                                    setEpostForm({
                                                        ...epostForm,
                                                        tillatAnalogSignatur: {
                                                            ...epostForm.tillatAnalogSignatur,
                                                            value: value,
                                                        },
                                                    });
                                                }}
                                            />
                                            <Modal
                                                useIcon={true}
                                                triggerClassName="icon icon-hjelp_stor icon--help"
                                                title="Tillat analog signatur"
                                                footer={
                                                    <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                                                        Lukk
                                                    </button>
                                                }
                                                children={
                                                    <>
                                                        <div>Tillat analog signatur</div>
                                                    </>
                                                }
                                            ></Modal>
                                        </div>
                                    </div>
                                )}
                                {kontaktPersoner.length > 0 && (
                                    <div>
                                        <h4>Kundekontakter</h4>
                                        <KontaktPerson
                                            kontaktPersoner={kontaktPersoner}
                                            onInputChange={(value) => onPersonInputChange(value)}
                                            valgtePersoner={valgtePersoner}
                                        />
                                    </div>
                                )}
                                <div>
                                    <h4>Rådgivere</h4>
                                    <Radgivere
                                        radgivere={radgivere}
                                        valgtRadgivere={valgteRadgivere}
                                        onInputChange={(value) => onInputChange(value)}
                                    />
                                </div>
                                <hr />
                                <h4>Andre mottakere som må signere:</h4>
                                {epostForm.andreMottakere &&
                                    epostForm.andreMottakere.map((kontaktperson, i) => {
                                        return (
                                            <div key={`kontaktperson_${i}`} className="kontaktperson">
                                                <div className="kontaktperson__details">
                                                    <Kontaktperson
                                                        kontaktperson={kontaktperson}
                                                        showRolleDropdown={true}
                                                        onValueChange={(kontaktperson) => {
                                                            const kontaktpersoner = [...epostForm.andreMottakere];
                                                            kontaktpersoner[i] = kontaktperson;
                                                            setEpostForm({
                                                                ...epostForm,
                                                                andreMottakere: kontaktpersoner,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="kontaktperson__actions">
                                                    <button
                                                        onClick={() => {
                                                            onDeleteKontaktpersonClick(i);
                                                        }}
                                                        className="btn btn--secondary btn--sm"
                                                    >
                                                        Slett
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                <button onClick={() => onAddKontaktperson()} className="btn btn btn btn--primary btn--sm">
                                    + Legg til kontaktperson
                                </button>
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <button className="btn btn--primary" onClick={onSendEpost}>
                                            Send til signering
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
