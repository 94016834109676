import React, { useEffect, useState, FormEvent, useRef } from 'react';
import { inputId } from '../../shared/lib/utils';
import { IInputElement } from '../../shared/lib/types';
import FormGroupLabelText from './FormGroupLabelText';
import FormError from './FormError';
import { ValidatorTypesEnum } from '../../shared/lib/validators';

export interface ITextareaInput extends IInputElement {
    required?: boolean;
    rows?: number;
    readonly?: boolean;
    answerHidden?: boolean;
    showErrorMessage?: boolean;
    error?: string;
}

const TextareaInput = (props: ITextareaInput) => {
    const [id] = useState(inputId());
    const input = useRef<HTMLTextAreaElement>(null);
    const required = props.validators?.includes(ValidatorTypesEnum.required);

    const handleChange = (e: FormEvent<HTMLTextAreaElement>) => {
        props.onInputChange(props.name, e.currentTarget.value);
    };

    function getClass() {
        let className = 'form-group form-group--textarea';

        if (props.validationError) {
            className += ' form-group--error';
        }

        return className;
    }
    function getTextAreaClass() {
        let className = 'form-group__input';

        if (props.disabled || props.readonly) {
            className += ' disabled';
        }

        if (props.error) {
            className += ' error-input';
        }

        return className;
    }

    useEffect(() => {
        if (props.focus === props.name) {
            input?.current?.focus();
        }
    }, [props.focus, props.name]);

    return (
        <div className={getClass()}>
            <label htmlFor={id}>
                <FormGroupLabelText label={`${props.label}${required ? '*' : ''}`} helpText={props.helpText} />
                <textarea
                    id={id}
                    ref={input}
                    className={getTextAreaClass()}
                    name={props.name}
                    aria-required={required}
                    required={required}
                    value={props.value ?? ''}
                    rows={props.rows ?? 4}
                    onChange={handleChange}
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    hidden={props.answerHidden}
                />
                {props.error && props.showErrorMessage && <FormError error={props.error} />}
                <span className="form-group__validation-error">{props.validationError && props.validationError.message}</span>
            </label>
        </div>
    );
};

export default TextareaInput;
