import { IInputElement, InputElementEnum } from '../../../../shared/lib/types';
import { ValidatorTypesEnum } from '../../../../shared/lib/validators';
import { IEpost } from '../../shared/lib/types';
import { IKontaktpersonForm, mapKontaktpersonFormToKontaktperson, newInputElement } from '../kundedetaljer/kundeFormHelper';

export interface IEpostForm {
    tittel: IInputElement;
    emne: IInputElement;
    melding: IInputElement;
    tillatAnalogSignatur: IInputElement;
    andreMottakere: Array<IKontaktpersonForm>;
}

export const createForm = (tittel: string): IEpost => {
    let epostForm = {
        tittel: tittel,
        emne: null,
        melding: null,
        tillatAnalogSignatur: false,
        kontaktpersoner: [],
        radgivere: [],
        andreMottakere: [],
    } as IEpost;

    return epostForm;
};

export const mapEpostToEpostForm = (epost: IEpost): IEpostForm => {
    let epostForm = {
        tittel: newInputElement(epost.tittel, 'Tittel på dokument'),
        emne: newInputElement(epost.emne, 'Emne i e-post til mottakere'),
        melding: newInputElement(epost.melding, 'Melding til e-post mottakere', InputElementEnum.textarea),
        tillatAnalogSignatur: newInputElement(epost.tillatAnalogSignatur, 'Tillat analog signatur', InputElementEnum.checkbox),
        andreMottakere: [],
    } as IEpostForm;

    // Set validators
    epostForm.emne.validators = [ValidatorTypesEnum.required];
    epostForm.melding.validators = [ValidatorTypesEnum.required];

    return epostForm;
};

export const mapEpostFormToEpost = (form: IEpostForm): IEpost => {
    let epost = {
        tittel: form.tittel.value,
        emne: form.emne.value,
        melding: form.melding.value,
        tillatAnalogSignatur: form.tillatAnalogSignatur.value,
        kontaktpersoner: form.andreMottakere?.map((person) => mapKontaktpersonFormToKontaktperson(person)),
    } as IEpost;

    return epost;
};
