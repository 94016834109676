import Modal from '../../../modal/Modal';
import CheckboxGroup from '../../../form/CheckboxGroup';
import { ICheckboxInput } from '../../../form/CheckboxInput';
import { InputElementEnum } from '../../../../shared/lib/types';
import { IRadgiver } from '../../shared/lib/types';
import { newInputElement } from '../kundedetaljer/kundeFormHelper';
import { getRadgiverFullName } from '../kundeHelper';

export interface IRadgivere {
    radgivere: Array<IRadgiver>;
    valgtRadgivere: Array<string>;
    onInputChange(rettigheter: Array<string>): void;
}
interface IRadgiverProp {
    readonly name: string;
    readonly id: string;
}
export default function Radgivere(props: IRadgivere) {
    const getRadgivereInputs = () => {
        return props.radgivere.map((radgiver, i) =>
            newInputElement(radgiver.brukerId, getRadgiverFullName(radgiver), InputElementEnum.checkbox, `rettigheter[${i}]`)
        );
    };
    const getSummary = (): Array<IRadgiverProp> => {
        return props.valgtRadgivere.map((brukerId) => {
            const radgiver = props.radgivere.find((radgiver) => radgiver.brukerId === brukerId);
            return {
                name: getRadgiverFullName(radgiver),
                id: radgiver.brukerId
            };
        });
    };

    return (
        <div className="radgivere-content">
            <ul className="no-bullets">
                {getSummary().map((item: IRadgiverProp) => {
                    return (
                        <div className="row">
                            <div className="col-md-6 d-md-flex radgivere">
                                <span onClick={() => props.onInputChange(props.valgtRadgivere.filter((value) => value !== item.id))}>X</span>
                                <li key={item.name}>{item.name}</li>
                            </div>
                        </div>
                    );
                })}
            </ul>
            <Modal
                triggerText="+ LEGG TIL"
                triggerClassName="btn btn--primary btn--sm mb-lg"
                title="LEGG TIL RÅDGIVERE"
                footer={
                    <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                        Lukk
                    </button>
                }
                children={
                    <>
                        <div className="row">
                            <div className="col-md-6 d-md-flex">
                                <button
                                    className="btn btn--primary--underline"
                                    onClick={() => {
                                        const radgivere = getRadgivereInputs() as Array<ICheckboxInput>;
                                        const selectedRadgivere = radgivere.map((item) => item.value);
                                        props.onInputChange(selectedRadgivere);
                                    }}
                                >
                                    Merk alle
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button
                                    className="btn btn--primary--underline"
                                    onClick={() => {
                                        props.onInputChange([]);
                                    }}
                                >
                                    Merk ingen
                                </button>
                            </div>
                        </div>
                        <div>
                            <CheckboxGroup
                                legend=""
                                label="Rettigheter"
                                type={InputElementEnum.checkboxGroup}
                                inputs={getRadgivereInputs() as Array<ICheckboxInput>}
                                onInputChange={(name, value) => props.onInputChange(value)}
                                focus=""
                                name="rettigheter"
                                value={props.valgtRadgivere}
                            />
                        </div>
                    </>
                }
            ></Modal>
        </div>
    );
}
